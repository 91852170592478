import React from 'react';
import '../styles/ScrollExample.css';

const ScrollExample = () => {
    return (
        <div className="scroll-example">
            <h1>Scroll Example with Buttons</h1>

            {/* Text Content */}
            {/* <div className="content"> */}
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                </p>

                <button className="btn">Button 1</button>

                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin, urna eu tristique ultricies, libero ligula blandit ante, nec tincidunt sapien eros id ligula. Proin ut lectus libero. Donec at purus nunc. Integer tempor purus at erat lacinia, in tristique velit viverra. Cras auctor nisi at erat feugiat euismod. Suspendisse fringilla feugiat nisi, sit amet facilisis lorem tempus eu. Integer ac elit libero. Nam at turpis sit amet nisi sodales pretium. Integer egestas leo eu orci tincidunt, a fermentum lectus fermentum.
                    Pellentesque euismod mi a ligula vestibulum, eu hendrerit nunc consectetur. Curabitur nec euismod elit, sit amet volutpat enim. Nam sit amet velit vitae sapien convallis malesuada. Sed posuere consectetur orci, eu condimentum enim blandit ac. Integer ac odio quis turpis posuere volutpat. Nam sagittis ultricies lacus, a feugiat erat mollis eu. Sed posuere velit sapien, et vehicula odio ullamcorper ac.
                </p>

                <button className="btn">Button 2</button>

                <p>
                    Ut feugiat, felis vitae porttitor vestibulum, metus sapien tristique felis, id eleifend nunc ante a ligula. Sed sollicitudin, risus et tempor faucibus, magna ante varius urna, nec gravida lectus odio a magna. Suspendisse suscipit, neque eget lobortis sollicitudin, erat risus eleifend tortor, vel consequat nisi dui id nulla. Sed pellentesque magna at justo efficitur, id aliquam odio cursus. Nulla nec dui nulla. Curabitur viverra auctor velit in efficitur. Suspendisse non purus sit amet eros malesuada auctor et ac odio.
                </p>

                <button className="btn">Button 3</button>

                <p>
                    Aenean tempor varius dolor, eu vulputate velit laoreet in. Donec fermentum lacinia nunc, ac tincidunt ipsum consectetur at. Mauris ultricies laoreet volutpat. Integer sit amet neque vehicula, euismod orci vitae, tempus mi. Curabitur volutpat bibendum nulla, vel sodales purus posuere eget. Integer a ultricies felis. Sed nec metus mauris. Nulla at lorem nec turpis tincidunt lacinia id a neque.
                </p>

                {/* More Text Content */}
                <p>
                    Mauris ac quam vel lacus tincidunt rhoncus. Aliquam euismod justo ac nisl volutpat, sed facilisis tortor consequat. Ut malesuada ligula turpis, eget ultricies nisl sodales id. In ultricies lobortis odio, sit amet malesuada ante faucibus at. Donec non turpis sed justo accumsan auctor. In iaculis ipsum quis felis efficitur, nec tempor sem interdum. Vivamus vulputate tincidunt ante, id mollis leo suscipit et. Donec at volutpat nunc. Aenean placerat, urna sit amet tincidunt maximus, turpis ipsum suscipit sem, non volutpat leo nisi ac velit.
                </p>
            {/* </div> */}
        </div>
    );
};

export default ScrollExample;
